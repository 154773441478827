// import {useRef, useEffect} from 'react';
// import { VectorMap } from '@south-paw/react-vector-maps';
// import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom';
// import world from '../assets/maps/world.json';

function Home() {
    // const Viewer = useRef(null);

    // useEffect(() => {
    //     Viewer.current.fitToViewer();
    // }, []);
    
    return (
        <div className='map'>
            {/* <UncontrolledReactSVGPanZoom
                ref={Viewer}
                SVGBackground="transparent"
                background="transparent"
                tool="pan"
                detectAutoPan={false}
                miniatureProps={{
                  position: 'none'
                }}
                toolbarProps={{
                  position: 'none'
                }}
                preventPanOutside={false}
                width={400}
                height={400}
            >
                <VectorMap {...world} />
            </UncontrolledReactSVGPanZoom>
                <VectorMap {...world} /> */}
                Map maybe
        </div>
    );
}
  
export default Home;