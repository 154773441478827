import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import Header from './components/Header';

import Home from './pages/Home';
import Countries from './pages/Countries';
import Tips from './pages/Tips';
import Weather from './pages/Weather';

function Template() {
  const pages = [
    { path: '/', title: 'Home', Element: Home },
    { path: '/countries', title: 'Places', Element: Countries },
    { path: '/tips', title: 'Travel Tips', Element: Tips },
    { path: '/weather', title: 'Weather', Element: Weather },
    { path: '/currency', title: 'Currency Converter', Element: Home },
    { path: '/dashboard', title: 'Dashboard', Element: Home },
    { path: '/languages', title: 'Languages', Element: Home },
  ];
  const location = useLocation();

  return (
    <div className="container">
      {/* Header */}
      <Header pages={pages} />

      {/* Content */}
      <div className='content'>
        <Routes location={location}>
          {pages.map(({path, title, Element}) => (
            <Route
              key={title}
              path={path}
              element={
                <Element />
              }
            />
          ))}
        </Routes>
      </div>

      {/* Footer */}
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Template />
    </BrowserRouter>
  );
}
export default App;