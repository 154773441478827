import { NavLink } from "react-router-dom";

function isActive(path) {
    if (window.location.pathname === path) {
        return "active";
    }
    return "";
}

function Header({ pages }) {
  return (
    <header className="header">
        <nav>
            <ul>
                {pages.map(({title, path}) => (
                    <li key={title} className={isActive(path)}>
                        <NavLink to={path}>
                            {title}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </nav>
    </header>
  );
}

export default Header;