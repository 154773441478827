export const countriesData = [
    {
        id: 'af',
        name: 'Afghanistan',
        full_name: 'Islamic Emirate of Afghanistan',
        local_name: 'أَفْغانِسْتان',
        continent: 'ASIA',
    },
    {
        id: 'al',
        name: 'Albania',
        full_name: 'Republic of Albania',
        local_name: 'Republika e Shqipërisë',
        continent: 'EUR',
    },
    {
        id: 'dz',
        name: 'Algeria',
        full_name: 'People\'s Democratic Republic of Algeria',
        local_name: 'الجمهورية الجزائرية الديمقراطية الشعبية',
        continent: 'AFR',
    },
];