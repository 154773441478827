import { countriesData } from "../data/countries";

function Countries() {
    return (
        <div className='countries-list'>
        {countriesData.map(country => (
            <div className='flag' key={country.id}>
                <img src={require(`../assets/images/flags/${country.id}.svg`)} alt={country.name} />
                <span className='name'>{country.name}</span>
            </div>
        ))}
        </div>
    );
}
  
export default Countries;